@import 'src/styles/variables';
@import 'src/styles/mixins';

.cardContainer {
  @include verticalGap(20px);

  .cardImage {
    width: 100%;
  }

  .cardSummaryContainer {
    @include verticalGap(12px);
    width: 100%;
    align-self: center;

    div {
      @include body-text('lg');
      display: flex;
      justify-content: space-between;
      color: $black60;

      span {
        color: $black100;
        font-weight: $boldFontWeight;
      }
    }
  }
}

.containerSavings {
  @include verticalGap(16px);

  align-items: center;
  padding: 8px 0;

  p {
    @include body-text('lg');

    span {
      font-weight: $boldFontWeight;
    }
  }

  .spacingLine {
    width: 100%;
    height: 1px;
    background-color: $black10;
  }

  .benefitsListContainer {
    gap: 16px;
  }
}

.buttonsContainer {
  @include verticalGap(20px);
}

.disclaimerContainer {
  @include disclaimerContainer;
}
